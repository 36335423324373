<template>
  <v-container class="legacy-container" fill-height fluid>
    <v-row class="legacy-bar">
      <v-col cols="12" sm="3">
        <v-img :src="url+'icons/logo-d+.png'" :height="32" :max-width="200" contain />
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row class="legacy-padding d-none d-md-flex">
    </v-row>
    <v-row class="legacy-content">
      <v-col cols="0" md="3">
      </v-col>
      <v-col cols="12" md="6" v-show="stage == 0">
        <div class="legacy-title">
          <span style="font-size: 24px;">Welcome back,</span> <br>
          continue to link your <span class="primary--text">legacy</span> account
        </div>
        <div class="legacy-body pa-4">
          <serverValidationField ref="emailField" v-model="email" label="Email" fieldClass="square-fields" :height="fieldHeight"
          :resolveFunction="validateEmail" :messages="emailFailReason" :inputDelay="10"/>
        </div>
        <v-btn tile depressed :height="64" :width="128" class="legacy-next-button next-button" color="inherit" :disabled="!canNext" @click="next()">
          Next
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" v-show="!loading && stage == 1">
        <div class="legacy-title" v-if="found">
          We have sent a confirmation code to the email:
          {{ email }}
        </div>
        <div class="legacy-title" v-else>
          Could not find the legacy email:
          {{ email }}
          <v-btn tile color="primary" class="legacy-next-button next-button my-4" :height="64" @click="stage = 0">
            Try again
          </v-btn>
        </div>
        <div class="legacy-body pa-4" v-show="found">
          <serverValidationField ref="codeField" v-model="code" label="Code" fieldClass="square-fields" :height="fieldHeight"
          :resolveFunction="validateCode" :messages="codeFailReason" :inputDelay="10"/>
        </div>
        <div class="legacy-body pa-4" v-show="!found">
        </div>
        <v-btn tile depressed :height="64" :width="128" class="legacy-next-button next-button" color="inherit" :disabled="!canNext" @click="next()" v-if="found">
          Next
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" v-show="!loading && stage == 2">
        <div class="legacy-title" v-if="valid">
          Account Found <v-icon :size="32" color="#40ff00">mdi-check-circle</v-icon> <br>
          Create your new password
        </div>
        <div class="legacy-title" v-else>
          Incorrect code
        </div>
        <div class="legacy-body pa-4" v-show="valid">
          <serverValidationField ref="passwordField" v-model="password" label="Password" fieldClass="square-fields" :height="fieldHeight"
          :resolveFunction="validatePassword" :messages="passwordFailReason" :password="true" :inputDelay="10"/>

          <serverValidationField ref="confirmPasswordField" v-model="confirmPassword" label="Confirm Password" fieldClass="square-fields" :height="fieldHeight"
          :resolveFunction="validateConfirmPassword"  :messages="confirmPasswordFailReason" :password="true" :inputDelay="10"/>
        </div>
        <div v-show="!valid">
          <v-btn tile color="primary" class="legacy-next-button next-button my-4" :height="64" @click="stage = 0">
            Try again
          </v-btn>
        </div>
        <v-btn tile depressed :height="64" :width="128" class="legacy-next-button next-button" color="inherit" :disabled="!canNext" @click="next()" v-if="valid">
          Next
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" v-show="!loading && stage == 3">
        <div class="legacy-title" v-if="recovered">
          Ready for liftoff, chief.
        </div>
        <div class="legacy-title" v-else>
          Something went wrong.
        </div>
        <div class="legacy-body pa-4 my-4">
          <v-btn v-if="recovered" tile color="primary" class="legacy-next-button next-button my-4" :height="64" @click="navigate('/')">
            Enter Disrupt+
          </v-btn>
          <v-btn v-else tile color="primary" class="legacy-next-button next-button my-4" :height="64" @click="stage = 0">
            Try again
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="6" v-show="loading">
        <div class="legacy-body text-center pa-4">
          <v-progress-circular :size="80" :width="2" color="primary" class="site-loader" indeterminate></v-progress-circular>
        </div>
      </v-col>
      <v-col cols="0" md="3">
      </v-col>
    </v-row>
    <v-row class="legacy-footer">
      <v-col cols="0" md="4">
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon large class="my-4">mdi-information</v-icon><br>
        This page is for users of the old Disrupt+ website.<br>
        If you do not have an account, register <span class="primary--text cursor-pointer" @click="navigate('/register')">here</span><br>
        <br>
        <i>Need help? Support@disrupt.plus</i>
      </v-col>
      <v-col cols="0" md="4">
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { Api } from '../js/api';

import serverValidationField from '../components/global/serverValidationField'

export default {
  name: 'Legacy',
  props: [],
  components: {
    serverValidationField
  },
  data: () => ({
    email: '',
    emailFailReason: '',
    code: '',
    codeFailReason: '',
    password: '',
    passwordFailReason: '',
    confirmPassword: '',
    confirmPasswordFailReason: '',
    loading: false,
    fieldHeight: 60,
    stage: -1,
    found: false,
    valid: false,
    recovered: false
  }),
  computed: {
    url () {
      return process.env.VUE_APP_CLIENT_PROTOCOL + process.env.VUE_APP_CLIENT_URL;
    },
    canNext () {
      if (this.stage == 0) {
        return this.isEmailValid;
      } else if(this.stage == 1) {
        return this.isCodeValid;
      } else if (this.stage == 2) {
        return this.isPasswordValid;
      }
      return false
    },
    isEmailValid () {
      if (this.$refs.emailField) {
        return this.$refs.emailField.isValueValid;
      }
      return false;
    },
    isCodeValid () {
       if (this.$refs.codeField) {
        return this.$refs.codeField.isValueValid;
      }
      return false;
    },
    isPasswordValid () {
      if (this.$refs.passwordField) {
        return this.$refs.passwordField.isValueValid && this.password == this.confirmPassword;
      }
      return false;
    }
  },
  watch: {
  },
  methods: {
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    next: function () {
      this.stage++;
      if (this.stage == 1) {
        this.requestReset();
      } else if (this.stage == 2) {
        this.checkCode();
      } else if (this.stage == 3) {
        this.recoverAccount();
      }
    },
    validateEmail: function (email) {
      return new Promise((resolve) => {
        const validation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email.toLowerCase().match(validation)) {
          this.emailFailReason = 'Incorrect email format';
          resolve(false);
          return;
        }

        this.emailFailReason = null;
        resolve(true);
      })
    },
    validateCode: function (newCode) {
      return new Promise((resolve) => {
        if (newCode.trim().length != 8) {
          this.codeFailReason = 'Incorrect code format';
          resolve(false);
          return;
        }
        this.codeFailReason = null;
        resolve(true);
      })
    },
    validatePassword: function (pass) {
      return new Promise((resolve) => {
        // length
        if (pass.length < 9) {
          this.passwordFailReason = 'Must be longer than 8 characters'
          resolve(false);
          return;
        }

        
        // // lowercase
        // if (!pass.match(/[a-z]/g)) {
        //   this.passwordFailReason = 'Must contain a lowercase letter';
        //   resolve(false);
        //   return;
        // }
        // // uppercase
        // if (!pass.match(/[A-Z]/g)) {
        //   this.passwordFailReason = 'Must contain an uppercase letter';
        //   resolve(false);
        //   return;
        // }
        // // numbers
        // if (!pass.match(/[0-9]/g)) {
        //   this.passwordFailReason = 'Must contain a number';
        //   resolve(false);
        //   return;
        // }

        // // symbols
        // if (!pass.match(/[^a-zA-Z\d\s:]/g)) {
        //   this.passwordFailReason = 'Must contain a symbol';
        //   resolve(false);
        //   return;
        // }

        this.passwordFailReason = null;
        resolve(true);
      })
    },
    validateConfirmPassword: function (pass) {
      return new Promise((resolve) => {
        if (this.password != pass) {
          this.confirmPasswordFailReason = 'Passwords do not match';
          resolve(false);
          return;
        }

        this.confirmPasswordFailReason = null;
        resolve(true);
      })
    },
    requestReset: function () {
      let scope = this;
      return new Promise((resolve) => {
        scope.loading = true;

        let data = {
          email: scope.email
        }
        Api.post('legacy/reset', data).then(function (res) {
          scope.found = res.data;
          scope.loading = false;
          resolve()
        })
      })
    },
    checkCode: function () {
      let scope = this;
      return new Promise((resolve) => {
        scope.loading = true;

        let data = {
          email: scope.email,
          code: scope.code
        }
        Api.post('legacy/validate', data).then(function (res) {
          if (res.data) {
            scope.valid = true;
            scope.code = res.data;
          } else {
            scope.valid = false;
          }
          scope.loading = false;
          resolve()
        })
      })
    },
    recoverAccount :function() {
      let scope = this;
      return new Promise((resolve) => {
        scope.loading = true;

        let data = {
          email: scope.email,
          password: scope.password,
          confirmPassword: scope.confirmPassword,
          code: scope.code
        }
        Api.post('legacy/update', data).then(function (res) {
          scope.recovered = res.data;
          scope.loading = false;

          if (res.data) {
            scope.$store.dispatch('updateLoggedIn').then(function (val) {
              if (val) {
                scope.$store.dispatch('getUser')
              }
            })
          }
          resolve()
        })
      })
    }
  },
  mounted () {
    this.stage = 0;
  }
}
</script>
<style lang="scss" scoped>
.legacy-bar {
  height: 60px;
}
.legacy-padding {
  height: 140px;
}
.legacy-content {
  // height: calc(100vh - 460px);
  min-height: 400px;
}
.legacy-title {
  text-align: center;
  font-size: 36px;
}
.legacy-body {
  max-width: 500px;
  margin: 0 auto;
}
.legacy-footer {
  height: 260px;
  font-size: 20px;
}

.legacy-next-button {
  font-size: 28px !important;
  text-transform: none !important;
  letter-spacing: 0px !important;
  display: block;
  margin: 0 auto;
}

.legacy-container {
  background-image: url('/backgrounds/legacy-background.jpeg');
  background-size: cover;
  background-position: center;
}
</style>
